<template>
  <div id="full">
    <div id="leftbottom">
      <p id="title0" v-if="gift_ranking.length > 0">1 小时内礼物榜：</p>
      <transition-group name="gift" tag="div">
        <div v-for="(it, i) in gift_ranking" :key="'gift_ranking' + it.uid">
          <a-badge :count="i + 1"><a-avatar :src="'/api/blive/get_avatar?uid=' + it.uid" /></a-badge>
          <span id="gift_rank">{{ it.name }} </span><span id="gift_price">{{ it.price }}</span>
        </div>
      </transition-group>
      <transition-group name="gift" tag="div">
        <div v-for="(it, i) in gift_list" :key="'gift_' + i + it.uid">
          <a-avatar :src="'/api/blive/get_avatar?uid=' + it.uid" />
          <span id="thank_gift">谢谢 {{it.name}} 赠送的 {{it.nums}} 个 {{it.gift_name}}</span>
        </div>
      </transition-group>
    </div>
    <div id="rightbottom">
      <div style="width: auto;height: 370px" id="chart"></div>
      <transition name="gift">
        <a-comment style="float: right" v-if="this.$route.query.is_vs === '0' && viewer_levels.length > 0">
          <template slot="actions"></template>
          <a slot="author" id="author">{{ now_level.uname }}</a>
          <a-avatar
              slot="avatar"
              :src="'/api/blive/get_avatar?uid=' + now_level.uid"
              :alt="now_level.uname"
              @dblclick="play_level"
          />
          <p slot="content" id="course-id">
            {{ formats(now_level.course_id) }}
          </p>
          <a-tooltip slot="datetime" :title="moment(now_level.add_time).format('YYYY-MM-DD HH:mm:ss')">
            <span>{{ moment(now_level.add_time).fromNow() }}</span>
          </a-tooltip>
        </a-comment>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import * as echarts from 'echarts';
export default {
  name: "LiveBg",
  data() {
    return {
      chartDom: null,
      myChart: null,
      moment: moment,
      now_level: {"uid": 2834728, "uname": "待投图", course_id: "a2ib32ch", add_time: moment()},
      viewer_levels: [],
      timer: null,
      gift_list: [],
      gift_ranking: [],
      v_rating: []
    }
  },
  created() {
    this.update();
    console.log(this.$route.query.is_vs)
  },
  mounted() {
    this.timer = setInterval(this.update, 16000);
  },
  methods: {
    scalarArrayEquals(array1,array2) {
      return array1.length===array2.length && array1.join(",") === array2.join(",");
    },
    render() {
      if (this.$route.query.is_vs !== '1') return;
      if (this.v_rating.length <= 1) return;
      this.chartDom = document.getElementById('chart');
      if (this.myChart === null)
        this.myChart = echarts.init(this.chartDom);
      this.myChart.clear();
      var option;

      option = {

        grid: [
          {left: '15%', top: '7%',},
          {right: '7%', top: '7%',},
          {left: '7%', bottom: '2%',},
          {right: '7%', bottom: '2%',}
        ],
        xAxis: {
          type: 'time',
          boundaryGap: false,
          show: false,
          splitLine: {
            show: false
          },
        },
        yAxis: {
          name: '分数',
          type: 'value',
          min: function (value) {
            return value.min - 15;
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            color: '#ffcc00',
            textBorderColor: '#000000',
            textBorderWidth: 5
          }
        },
        series: [{
          data: this.v_rating,
          type: 'line',
          smooth: true,
          symbolSize: 15,
          symbol: "image://https://yohane.cc/toadette_icon.png",
          itemStyle: {
            color: 'black'
          },
          lineStyle: {
            color: 'rgba(65,154,255, 1)'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 1, color: 'rgba(0,0,255,0.01)' // 0% 处的颜色
              }, {
                offset: 0.8, color: 'rgba(65,154,255,0.15)' // 100% 处的颜色
              }, {
                offset: 0, color: 'rgba(65,154,255,0.75)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          }
        }]
      };
      option && this.myChart.setOption(option);
    },
    formats(course_id) {
      var d = course_id.replace(/[^\w]/g, "");
      return d.substr(0, 3).toUpperCase() + '-' + d.substr(3, 3).toUpperCase() + '-' + d.substr(6, 3).toUpperCase();
    },
    update() {
      console.log("update");
      const that = this;
      axios.get("/api/blive/viewer_level")
      .then((res) => {
        that.viewer_levels = res.data.response;
        if (that.viewer_levels.length > 0) {
          that.now_level = res.data.response[0];
        }
      })
      axios.get("/api/blive/gifts?secago=180")
      .then((res) => {
        if (res.data.code === 0)
          that.gift_list = res.data.response;
      })
      axios.get("/api/blive/gift_ranking?secago=3600")
      .then((res) => {
        if (res.data.code === 0)
          that.gift_ranking = res.data.response;
      })
      axios.get("/api/blive/versus_rating?secago=3600")
          .then((res) => {
            if (res.data.code === 0) {
              // var l = res.data.response.length;
              // for (let i = 0; i < l; i++) {
              //   res.data.response[i][1] = Math.floor(res.data.response[i][1])
              // }
              // console.log(res.data.response)
              if (!that.scalarArrayEquals(that.v_rating, res.data.response)) {
                that.v_rating = res.data.response;
                that.render();
              }
            }
          })
    },
    play_level() {
      const that = this;
      axios.get("/api/blive/play_level?id=" + this.now_level.id)
      .then(() => {
        that.viewer_levels.pop();
        that.now_level = {}
      })
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.lug.ustc.edu.cn/css?family=Changa One");
@import url("https://fonts.lug.ustc.edu.cn/css?family=Ma Shan Zheng");
#author {
  color: #ffffff !important;
  font-family: "Ma Shan Zheng";
  font-weight: 800;
  font-size: 19px !important;
  line-height: 19px !important;
  text-shadow: -2px -2px #000000,-2px -1px #000000,-2px 0px #000000,-2px 1px #000000,-2px 2px #000000,-1px -2px #000000,-1px -1px #000000,-1px 0px #000000,-1px 1px #000000,-1px 2px #000000,0px -2px #000000,0px -1px #000000,0px 0px #000000,0px 1px #000000,0px 2px #000000,1px -2px #000000,1px -1px #000000,1px 0px #000000,1px 1px #000000,1px 2px #000000,2px -2px #000000,2px -1px #000000,2px 0px #000000,2px 1px #000000,2px 2px #000000
}
#thank_gift, #gift_rank {
  color: #ffffff !important;
  font-family: "Microsoft JhengHei UI";
  font-weight: 700;
  font-size: 21px !important;
  line-height: 21px !important;
  text-shadow: -2px -2px #000000,-2px -1px #000000,-2px 0px #000000,-2px 1px #000000,-2px 2px #000000,-1px -2px #000000,-1px -1px #000000,-1px 0px #000000,-1px 1px #000000,-1px 2px #000000,0px -2px #000000,0px -1px #000000,0px 0px #000000,0px 1px #000000,0px 2px #000000,1px -2px #000000,1px -1px #000000,1px 0px #000000,1px 1px #000000,1px 2px #000000,2px -2px #000000,2px -1px #000000,2px 0px #000000,2px 1px #000000,2px 2px #000000
}
#gift_price {
  color: #ffc500 !important;
  font-family: "Microsoft JhengHei UI";
  font-weight: 750;
  font-size: 21px !important;
  line-height: 21px !important;
  text-shadow: -2px -2px #000000,-2px -1px #000000,-2px 0px #000000,-2px 1px #000000,-2px 2px #000000,-1px -2px #000000,-1px -1px #000000,-1px 0px #000000,-1px 1px #000000,-1px 2px #000000,0px -2px #000000,0px -1px #000000,0px 0px #000000,0px 1px #000000,0px 2px #000000,1px -2px #000000,1px -1px #000000,1px 0px #000000,1px 1px #000000,1px 2px #000000,2px -2px #000000,2px -1px #000000,2px 0px #000000,2px 1px #000000,2px 2px #000000

}
#course-id, #title0 {
  color: #ffd600 !important;
  font-family: "Changa One";
  font-weight: 700;
  font-size: 18px !important;
  line-height: 18px !important;
  text-shadow: -2px -2px #000000,-2px -1px #000000,-2px 0px #000000,-2px 1px #000000,-2px 2px #000000,-1px -2px #000000,-1px -1px #000000,-1px 0px #000000,-1px 1px #000000,-1px 2px #000000,0px -2px #000000,0px -1px #000000,0px 0px #000000,0px 1px #000000,0px 2px #000000,1px -2px #000000,1px -1px #000000,1px 0px #000000,1px 1px #000000,1px 2px #000000,2px -2px #000000,2px -1px #000000,2px 0px #000000,2px 1px #000000,2px 2px #000000
}
#leftbottom {
  bottom: 50px;
  left: 0;
  position: absolute;
  min-width: 320px;
}
#rightbottom {
  bottom: 0;
  right: 0;
  position: absolute;
  min-width: 500px;
}
.gift-enter-active, .gift-leave-active {
  transition: all 1s;
}
.gift-enter, .gift-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
